/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { useForm } from "react-hook-form";
import { apiService } from "../service/api.service";
import Swal from "sweetalert2";
import ContactUs from "./ContactUs";
import IconLinks from './IconLinks'; // Import the IconLinks component
const Header = ({ open, setOpen }) => {
    const ADVANCE_FEATURES = [
        {
            "name": "Image Generation",
            "image": require("../assets/images/generate-img.png"),
            "description": "Create images using words",
            "route": "/?chatType=image",
            "type": "image"
        },
        {
            "name": "Upload PDF",
            "image": require("../assets/images/pdf.png"),
            "description": "Chat with any PDF",
            "route": "/?chatType=pdf",
            "type": "pdf"
        },
        {
            "name": "Link",
            "image": require("../assets/images/link.png"),
            "description": "Paste a Link & Ask",
            "route": "/?chatType=link",
            "type": "link"
        }
    ];

    const subscribe = () => {
        if (!globalContext.loggedIn) {
            globalContext.setLock(false);
            navigate(`/checkout?trial=${globalContext.planList[0].id}`, { replace: true });
        } else {
            globalContext.setLock(false);
            globalContext.setPlanModal(true);
        }
    }

    const handleLoggedInPasswordReset = () => {
        // Option 1: Redirect to account settings
        navigate('/account-setting');
      
        // Option 2: Show a modal (uncomment if you prefer this option)
        // Swal.fire({
        //   title: 'Change Password',
        //   text: 'You are already logged in. Would you like to change your password in account settings?',
        //   icon: 'info',
        //   showCancelButton: true,
        //   confirmButtonColor: '#3085d6',
        //   cancelButtonColor: '#d33',
        //   confirmButtonText: 'Yes, go to settings'
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     navigate('/account-setting');
        //   }
        // });
      };
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [queryParams] = useSearchParams();
    const globalContext = useContext(GlobalContext);
    const { showForgotPassword, setShowForgotPassword } = useContext(GlobalContext);


    const [scroll, setScroll] = useState(false);
    // const [showLogin, setShowLogin] = useState(false);
    const { showLogin, setShowLogin } = useContext(GlobalContext);
    const [showSignup, setShowSignup] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [passType, setPassType] = useState("password");
    const [selectedPlan, setSelectedPlan] = useState(null);

    const { register, handleSubmit, formState: { errors }, setError } = useForm();
    const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, setError: setError1, reset } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, setError: setError2 } = useForm();

    useEffect(() => {
        if (!globalContext.loggedIn && (location.pathname !== "/" && !location.pathname.includes("/reset-password/") && location.pathname !== "/privacy-policy" && location.pathname !== "/term-condition" && location.pathname !== "/refund-policy")) {
            navigate("/?chatType=text", { replace: true });
        }
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 10);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (queryParams.get('action') === 'login') {
            setShowLogin(true);
        }
        if (queryParams.get('chatType')) {
            const chatType = queryParams.get('chatType');
            if (["image", "pdf", "link"].includes(chatType)) {
                if (globalContext.loggedIn) {
                    if (!globalContext.userInfo?.subscription_id) {
                        globalContext.setPlanModal(true);
                    }
                } else {
                    setShowLogin(true);
                }
            }
        }
        if (["pdf", "image", "link"].includes(queryParams.get("chatType")) && (!globalContext.loggedIn || !globalContext.userInfo?.subscription_id)) {
            globalContext.setDisableGeneration(true);
        } else {
            globalContext.setDisableGeneration(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location]);

    useEffect(() => {
        getPlanList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalContext.refreshProfile, location]);

    useEffect(() => {
        if (globalContext.loggedIn) {
            getMyDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalContext.refreshProfile, location]);

    const getMyDetails = () => {
        apiService.getMyProfile().then((res) => {
            if (res.status === 200) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setUserInfo(res.data.data);
            }
        }).catch((err) => {
            console.log("my details error: ", err);
        })
    };

    const getPlanList = () => {
        apiService.getPlans().then((res) => {
            if (res.status === 200) {
                globalContext.setPlanList(res.data.data);
                setSelectedPlan(res.data.data[0].id);
            }
        }).catch((err) => {
            console.log("plan list error: ", err);
        })
    };

    const onSubmitLogin = (data) => {
        globalContext.setLoader(true);
        apiService.login(data).then((res) => {
            globalContext.setLoader(false);
            if (res.status === 200) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setLoggedIn(true);
                globalContext.setUserInfo(res.data.data);
                globalContext.setSessionComplete(false);
                setShowLogin(false);
                navigate("/?chatType=text", { replace: true });
            }
        }).catch((err) => {
            console.log("login error: ", err);
            globalContext.setLoader(false);
            setError('password', { type: 'custom', message: err.response.data.detail });
        })
    };

    const onSubmitSignup = (data) => {
        globalContext.setLoader(true);
        apiService.signup(data).then((res) => {
            globalContext.setLoader(false);
            if (res.status === 201) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setLoggedIn(true);
                globalContext.setUserInfo(res.data.data);
                globalContext.setSessionComplete(false);
                setShowLogin(false);
                setShowSignup(false);
                navigate("/?chatType=text", { replace: true });
            }
        }).catch((err) => {
            console.log("signup error: ", err);
            globalContext.setLoader(false);
            setError2('email', { type: 'custom', message: err.response.data.detail });
        })
    };

    const onSubmitForgotPassword = (data) => {
        globalContext.setLoader(true);
        apiService.forgotPassword(data).then((res) => {
          globalContext.setLoader(false);
          if (res.status === 200) {
            Swal.fire('Success!', 'A password reset link has been sent to your email address.', 'success');
            globalContext.setShowForgotPassword(false);
          }
        }).catch((err) => {
          console.log("forgot password error: ", err);
          globalContext.setLoader(false);
          setError1('email', { type: 'custom', message: err.response.data.detail });
        })
      };

    const changeChatModel = (type) => {
        navigate(type);
    };

    const upgradePlan = () => {
        globalContext.setPlanModal(true);
    };

    const logout = () => {
        localStorage.removeItem("user-info");
        globalContext.setLoggedIn(false);
        navigate("/", { replace: true });
    };

    return (
        <>
            <section className={scroll ? "header scrolled" : "header"}>
            {/* -------------------- HEADER SHOW ABOVE 770 CSS ----------------- */}
                  
                <div className="header-left">
                            <div className="sidebar-head">
                                <Link to="/?chatType=chat"><h2>Helpr</h2></Link>
                            </div>
                          
                        </div>
                    <div className="header-right">
                        <div className="header-right-links-above-770">
                        <IconLinks /> {/* Add the IconLinks component here */}

                            </div>
                            <div className="header-right-login">
                            {globalContext.loggedIn ?
                                <>
                                    <span id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setOpen(true)}>
                                        <img className="account-icon" src={globalContext?.userInfo?.profile_image ? globalContext.userInfo?.profile_image :  require("../assets/images/profile.png")} alt="img" />
                                    </span>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        {/* <li><span><img className="account-icon" src={globalContext?.userInfo?.profile_image ? globalContext.userInfo?.profile_image :  require("../assets/images/profile.png")} alt="img" /> {globalContext.userInfo?.name ? globalContext.userInfo?.name : globalContext.userInfo?.email}{!globalContext.userInfo?.subscription_id && <span>Free</span>}</span></li> */}
                                        <li onClick={() => navigate("/account-setting")}><span>My Account</span></li>
                                        <li onClick={() => navigate("/privacy-policy")}><span>Privacy</span></li>
                                        <li onClick={() => navigate("/terms-conditions")}><span>Terms</span></li>
                                        
                                        <li><span>Contact: support@helpr.chat</span></li>
                                        {!globalContext.userInfo?.subscription_id && <li onClick={() => upgradePlan()}><span>Upgrade to Pro</span></li>}
                                        <li onClick={() => logout()}><span>Logout</span></li>
                                    </ul>
                                </>
                                :
                                <Button type="button" variant="unset" onClick={() => setShowLogin(true)}>Login</Button>
                            }
                            </div>
                        </div>
                 

            {/* -------------------- HEADER SHOW BELOW 770 CSS ----------------- */}

                

              





            </section>

            {/* Existing Modals here... */}
            <Modal show={showLogin} fullscreen={true} onHide={() => { setShowLogin(false); navigate("/", { replace: true }) }} className="subscription-popup forgot-popup">
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body>
        <Row className="justify-content-center">
            <Col md={7} xl={5} xxl={4} className="col-12 p-0">
                <div className="login-content-inner">
                    <div className="login-content-head">
                        <h2>Sign In</h2>
                        <p>Use registered email address and password.</p>
                    </div>
                    <div className="login-content-body">
                        <Form onSubmit={handleSubmit(onSubmitLogin)}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" placeholder="Enter Email" {...register("email", {
                                    required: "Email is required.", pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: "Invalid email address."
                                    }
                                })} />
                                {errors.email && <span className="err-msg">{errors.email.message}</span>}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Enter Password</Form.Label>
                                <div className="password-area">
                                    <div className="password-area-inner">
                                        <Form.Control type={passType} placeholder="Password" {...register("password", { required: "Password is required" })} onKeyDown={(e) => { if (e.key === " ") e.preventDefault() }} />
                                        <i onClick={() => setPassType(passType === "password" ? "text" : "password")} className={`fa-solid fa-eye${passType === "password" ? "-slash" : ""}`} />
                                    </div>
                                    {errors.password && <span className="err-msg">{errors.password.message}</span>}
                                </div>
                                <p className="forgot-text">
                                <span
  tabIndex={0}
  role="link"
  style={{
    cursor: "pointer",
    color: "#007bff",
    textDecoration: "underline",
    display: "inline-block"
  }}
  onClick={() => {
    if (globalContext.loggedIn) {
      // User is logged in, redirect to account settings or show modal
      handleLoggedInPasswordReset();
    } else {
      // User is not logged in, show forgot password modal
      globalContext.setShowForgotPassword(true);
      globalContext.setShowLogin(false);
    }
  }}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      if (globalContext.loggedIn) {
        handleLoggedInPasswordReset();
      } else {
        globalContext.setShowForgotPassword(true);
        globalContext.setShowLogin(false);
      }
    }
  }}
>
  Forgot Password?
</span>
                                </p>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Sign In
                            </Button>
                            {/* Add the signup link here */}
                            <p className="mt-3">
                                Don’t have an account? 
                                <span
  tabIndex={0}
  role="link"
  style={{
    cursor: "pointer",
    color: "#007bff",
    textDecoration: "underline",
    display: "inline-block"
  }}
  onClick={() => {
    navigate('/checkout');
    setShowLogin(false);
  }}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      navigate('/checkout');
      setShowLogin(false);
    }
  }}
>
  Subscribe
</span>
                            </p>
                        </Form>
                    </div>
                </div>
            </Col>
        </Row>
    </Modal.Body>
</Modal>


{/* Forgot Password Modal */}
<Modal 
  show={globalContext.showForgotPassword} 
  onHide={() => globalContext.setShowForgotPassword(false)} 
  className="subscription-popup forgot-popup"
>
  <Modal.Header closeButton>
    <Modal.Title>Reset Password</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form onSubmit={handleSubmit1(onSubmitForgotPassword)}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email Address</Form.Label>
        <Form.Control 
          type="email" 
          placeholder="Enter Email" 
          {...register1("email", {
            required: "Email is required.",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Invalid email address."
            }
          })} 
        />
        {errors1.email && <span className="err-msg">{errors1.email.message}</span>}
      </Form.Group>
      <Button variant="primary" type="submit">
        Send Reset Link
      </Button>
    </Form>
  </Modal.Body>
</Modal>



        </>
    );
};

export default Header;